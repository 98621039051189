/* Target is not multiplied here cause we already
   did it pre-emptively for this case. */
export const reviewsCoord = {
  position: [6.3, 8.4, 0.14],
  target: [7.4, 8.4, -2.03],
};

export const mobileReviewsCoord = {
  position: [5.4661, 8.4, 1.785],
  target: [7.4, 8.4, -2.03],
};

/* target is multiplied by 0.3 which is the
   scale factor of the scene. */
export const careerCoord = {
  position: [0.7, 2.42, 3.3],
  target: [2.83 * 0.3 , 8.14 * 0.3, 4.48 * 0.3],
}

export const mobileCareerCoord = {
  position: [0.6353, 2.44, 4.1450],
  target: [2.83 * 0.3 , 8.14 * 0.3, 4.48 * 0.3],
}

/* target is multiplied by 0.3 which is the
   scale factor of the scene. */
export const contactsCoord = {
  position: [-6.33, 15.2, -15.925],
  target: [-16.22 * 0.3 , 16.995 * 0.3, -25.33 * 0.3],
}

/* The below 2 are for initial entry camera animation */
export const initialPreStartCoord = {
  position: [8.355, 15.5, 89.744],
  target: [9.0466, 10.77, 73.195],
}

export const initialStartCoord = {
  position: [18.12, 6.6024, 26.18],
  target: [9.9841 , 3.7322, 10.6802],
}

export const mobileInitialStartCoord = {
  position: [26.629, 9.617, 42.483],
  target: [9.9841 , 3.7322, 10.6802],
}
import * as THREE from 'three'
import Experience from './Experience.js'
import gsap from 'gsap';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { initialStartCoord, mobileInitialStartCoord } from './CameraPositions.js';

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.setInstance()
        this.setControls()

        /* Bind 'Esc' key to restore original camera coords. */
        window.addEventListener('keyup', event => {
            if (event.code === 'Escape') {
                this.returnToHomeCoord();
            }
        });
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 100)
        // this.instance.position.set(...initialPreStartCoord.position)

        // This is the initial position we want for the spin of the scene.
        this.instance.position.set(19.9, 21, 23.95)

        // Camera.lookAt does not work when you have orbit controls.
        // this.instance.lookAt(0, 4.56, -0.42);
        this.scene.add(this.instance)
        window.camera = this.instance
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        /* In order to control what the camera looks at,
           we have to set it here. */

        // This is the initial position we want for the spin of the scene.
        this.controls.target = new THREE.Vector3(0, 4.56, -0.42);
        // this.controls.target = new THREE.Vector3(...initialPreStartCoord.target);

        // Restrict orbit controls from going below the floor.
        this.controls.maxPolarAngle = Math.PI / 2;
        window.cameraControls = this.controls;

    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.controls.update()
    }

    setOriginalCameraCoord() {
        window.originalCameraPosition = [
          this.instance.position.x,
          this.instance.position.y,
          this.instance.position.z,
        ];
        window.originalCameraTarget = [
          this.controls.target.x,
          this.controls.target.y,
          this.controls.target.z,
        ];
    };

    returnToHomeCoord() {
        this.experience.audio.setPlaybackRate(1);
        this.experience.audio.setDuration(1);
        this.experience.audio.setOffset(0.5);
        this.experience.audio.play(this.experience.resources.items.audioWhoosh)
        this.experience.resetTopControls();

        const coords = !window.isMobile ? initialStartCoord : mobileInitialStartCoord;
        gsap.to(this.instance.position, {
            duration: 1,
            x: coords.position[0],
            y: coords.position[1],
            z: coords.position[2],
            paused: true,
            ease: 'none'
          }).play();
        gsap.to(this.controls.target, {
            duration: 1,
            x: coords.target[0],
            y: coords.target[1],
            z: coords.target[2],
            paused: true,
            ease: 'none',
        }).play();
    }

    returnToPreviousCameraCoord() {
        if (!window.originalCameraPosition || !window.originalCameraPosition.length === 3) {
            return;
        }
        if (!window.originalCameraTarget || !window.originalCameraTarget.length === 3) {
            return;
        }
        gsap.to(this.instance.position, {
            duration: 1,
            x: window.originalCameraPosition[0],
            y: window.originalCameraPosition[1],
            z: window.originalCameraPosition[2],
            paused: true,
            ease: 'none'
          }).play();
        gsap.to(this.controls.target, {
            duration: 1,
            x: window.originalCameraTarget[0],
            y: window.originalCameraTarget[1],
            z: window.originalCameraTarget[2],
            paused: true,
            ease: 'none',
        }).play();
    }
}
import Experience from '../Experience.js'
import gsap from 'gsap';
import * as THREE from 'three';
import Environment from './Environment/Environment.js';;
import { water } from './Ocean/Ocean.js';
import { lightParticles } from './LightParticles/LightParticles.js';
import { Box3, BoxGeometry, Mesh, Vector3 } from 'three';
import { initialEntryCoord, initialStartCoord, mobileInitialStartCoord, reviews, setOriginalCameraCoord } from '../CameraPositions.js';
import { goToSceneCoord } from '../Utils/Navigation.js';
import loadingScreenVertexShader from './LoadingScreen/shaders/vertex.glsl';
import loadingScreenFragmentShader from './LoadingScreen/shaders/fragment.glsl';

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.clock = new THREE.Clock();
        this.initLoadingScreen();
        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            // this.floor = new Floor()
            this.environment = new Environment()

            /* Init career materials */
            this.initCareerMaterials();

            /* Init review materials */
            this.initReviewMaterials();

            this.initScene();
        });
        this.resources.on('progress', () => {
            const progress = this.resources.progressRatio;
            gsap.to(this.overlay.material.uniforms.uProgress, { duration: 0.5, value: progress, });
        });
    }

    update()
    {
        // if (this.roomModel) {
        //     this.roomModel.rotation.y += 0.005;
        // }
        // if (this.roomModel2) {
        //     this.roomModel2.rotation.y += 0.005;
        // }
        // if (this.roomModel3) {
        //     this.roomModel3.rotation.y += 0.005;
        // }
        // if (this.roomModel4) {
        //     this.roomModel4.rotation.y += 0.005;
        // }
        this.overlay.material.uniforms.uTime.value = this.clock.getElapsedTime();
        if (this.water) {
            const elapsedTime = this.clock.getElapsedTime();
            this.water.material.uniforms.uTime.value = elapsedTime;
        }
        if (this.lightParticles) {
            const elapsedTime = this.clock.getElapsedTime();
            this.lightParticles.material.uniforms.uTime.value = elapsedTime;
        }

    }

    initCareerMaterials() {
        const snappymob = this.resources.items.snappymob;
        snappymob.flipY = false;
        const snappymobMaterial = new THREE.MeshBasicMaterial({
            transparent: true,
            alphaMap: snappymob,
            color: 0x000000,
        });

        const configura = this.resources.items.configura;
        configura.flipY = false;
        const configuraMaterial = new THREE.MeshBasicMaterial({
            transparent: true,
            alphaMap: configura,
            color: 0x000000,
        });

        const compass = this.resources.items.compass;
        compass.flipY = false;
        const compassMaterial = new THREE.MeshBasicMaterial({
            transparent: true,
            alphaMap: compass,
            color: 0x000000,
        });

        this.careerMaterials = [
            snappymobMaterial,
            configuraMaterial,
            compassMaterial,
        ];
        this.currentCareerIndex = 0;
    }

    initReviewMaterials() {
        const reviewStart = this.resources.items.reviewStart;
        reviewStart.encoding = THREE.sRGBEncoding;
        reviewStart.flipY = false;
        const reviewStartMaterial = new THREE.MeshBasicMaterial({
            map: reviewStart,
        });

        const reviews = [];
        for (let i = 1; i < 6; i++) {
            const currReview = this.resources.items['review'+i];
            currReview.encoding = THREE.sRGBEncoding;
            currReview.flipY = false;
            const currReviewMaterial = new THREE.MeshBasicMaterial({
                map: currReview,
            });
            reviews.push(currReviewMaterial);
        }

        this.reviewMaterials = [
            reviewStartMaterial,
            ...reviews,
        ];
        this.currentReviewIndex = 0;
    }

    initScene() {
        this.sceneGroup = new THREE.Group();
        this.roomModel = this.experience.resources.items.roomModel.scene;
        const bakedTexture = this.experience.resources.items.roomTexture;
        bakedTexture.flipY = false;
        bakedTexture.encoding = THREE.sRGBEncoding;

        const roomTexture = new THREE.MeshBasicMaterial({ map: bakedTexture });
        this.roomModel.traverse((child) => {
            child.material = roomTexture;
        });
        const scaleFactor = 0.3;
        this.roomModel.scale.set(scaleFactor, scaleFactor, scaleFactor);
        this.sceneGroup.add(this.roomModel);

        const roomModel2 = this.experience.resources.items.roomModel2.scene;
        const bakedTexture2 = this.experience.resources.items.roomTexture2;
        bakedTexture2.flipY = false;
        bakedTexture2.encoding = THREE.sRGBEncoding;
        const roomTexture2 = new THREE.MeshBasicMaterial({ map: bakedTexture2 });
        roomModel2.traverse((child) => {
            child.material = roomTexture2;
        });

        roomModel2.scale.set(scaleFactor, scaleFactor, scaleFactor);
        this.roomModel2 = roomModel2;
        this.sceneGroup.add(roomModel2);

        const roomModel3 = this.experience.resources.items.roomModel3.scene;
        const bakedTexture3 = this.experience.resources.items.roomTexture3;
        bakedTexture3.flipY = false;
        bakedTexture3.encoding = THREE.sRGBEncoding;
        const roomTexture3 = new THREE.MeshBasicMaterial({ map: bakedTexture3 });
        roomModel3.traverse((child) => {
            child.material = roomTexture3;
            switch (child.name) {
                case 'about_plane':
                    const aboutMe = this.resources.items.aboutMe;
                    aboutMe.flipY = false;
                    child.material = new THREE.MeshBasicMaterial({
                        alphaMap: aboutMe,
                        transparent: true,
                        color: 0xffffff,
                    });
                    break;
            }
        });

        roomModel3.scale.set(scaleFactor, scaleFactor, scaleFactor);
        this.roomModel3 = roomModel3;
        this.sceneGroup.add(roomModel3);

        const roomModel4 = this.experience.resources.items.roomModel4.scene;
        const bakedTexture4 = this.experience.resources.items.roomTexture4;
        bakedTexture4.flipY = false;
        bakedTexture4.encoding = THREE.sRGBEncoding;
        const roomTexture4 = new THREE.MeshBasicMaterial({ map: bakedTexture4 });
        roomModel4.traverse((child) => {
            child.material = roomTexture4;
        });

        roomModel4.scale.set(scaleFactor, scaleFactor, scaleFactor);
        this.roomModel4 = roomModel4;
        this.sceneGroup.add(roomModel4);

        /* Interactables */
        const interactables = this.experience.resources.items.interactableModels.scene;
        interactables.scale.set(scaleFactor, scaleFactor, scaleFactor);
        this.sceneGroup.add(interactables);
        interactables.traverse(child => {
            if (child.name !== 'Scene') {
                this.experience.interactables.push(child);

                switch (child.name) {
                    case 'career_plane':
                        this.careerPlane = child;
                        child.material = this.careerMaterials[this.currentCareerIndex];
                        break;
                    case 'review_plane':
                        this.reviewPlane = child;
                        child.material = this.reviewMaterials[this.currentReviewIndex];
                        break;
                    case 'career_sign':
                        const careerSign = this.resources.items.careerSign;
                        careerSign.flipY = false;
                        const careerSignMaterial = new THREE.MeshBasicMaterial({
                            transparent: true,
                            alphaMap: careerSign,
                            color: 0x000000,
                        });
                        this.careerSign = child;
                        child.material = careerSignMaterial;
                        break;
                    case 'review_sign':
                        const reviewSign = this.resources.items.reviewSign;
                        reviewSign.flipY = false;
                        const reviewSignMaterial = new THREE.MeshBasicMaterial({
                            transparent: true,
                            alphaMap: reviewSign,
                            color: 0x000000,
                        });
                        this.reviewSign = child;
                        child.material = reviewSignMaterial;
                        break;
                    case 'contact_sign':
                        const contactSign = this.resources.items.contactSign;
                        contactSign.flipY = false;
                        const contactSignMaterial = new THREE.MeshBasicMaterial({
                            transparent: true,
                            alphaMap: contactSign,
                            color: 0x000000,
                        });
                        this.contactSign = child;
                        child.material = contactSignMaterial;
                        break;
                }
            }
        })

        this.water = water;
        this.lightParticles = lightParticles;

        if (!window.isMobile) {
            this.sceneGroup.add(lightParticles);
            this.sceneGroup.add(water);
        }

        this.sceneGroup.position.set(0, 0, 0);
        this.scene.add(this.sceneGroup);
    }

    nextCareerItem() {
        if (!this.careerPlane) {
            return;
        }
        this.experience.audio.playClick();
        if (this.currentCareerIndex < this.careerMaterials.length - 1) {
            this.currentCareerIndex += 1;
        } else {
            this.currentCareerIndex = 0;
        }
        this.careerPlane.material = this.careerMaterials[this.currentCareerIndex];
    }

    prevCareerItem() {
        if (!this.careerPlane) {
            return;
        }
        this.experience.audio.playClick();
        if (this.currentCareerIndex > 0 ) {
            this.currentCareerIndex -= 1;
        } else {
            this.currentCareerIndex = this.careerMaterials.length - 1;
        }
        this.careerPlane.material = this.careerMaterials[this.currentCareerIndex];
    }

    nextReviewItem() {
        if (!this.reviewPlane) {
            return;
        }
        this.experience.audio.playClick();
        if (this.currentReviewIndex < this.reviewMaterials.length - 1) {
            this.currentReviewIndex += 1;
        } else {
            this.currentReviewIndex = 1;
        }
        this.reviewPlane.material = this.reviewMaterials[this.currentReviewIndex];
    }

    prevReviewItem() {
        if (!this.reviewPlane) {
            return;
        }
        this.experience.audio.playClick();
        if (this.currentReviewIndex > 1 ) {
            this.currentReviewIndex -= 1;
        } else {
            this.currentReviewIndex = this.reviewMaterials.length - 1;
        }
        this.reviewPlane.material = this.reviewMaterials[this.currentReviewIndex];
    }

    initAmbientAudio() {
        /* Init initial whoosh as user enters the scene */
        this.experience.audio.play(this.resources.items.audioWhoosh);
        this.experience.ambientAudio.play(
            this.resources.items.audioOcean,
            true
        );
        this.experience.ambientAudio2.play(
            this.resources.items.audioHeavenlyAmbience
        );
    }

    initLoadingScreen() {
        /**
         * Overlay
         */
        const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
        const overlayMaterial = new THREE.ShaderMaterial({
            transparent: true,
            uniforms:
            {
                uTime: { value: 0.0 },
                uAlpha: { value: 1 },
                uProgress: { value: 0.0 },
                uBgColor: { value: new THREE.Color('#06072d')},
                uOceanColor: { value: new THREE.Color('#51D3AD')},
                uWaterfallColor: { value: new THREE.Color('#31c196')},
                uIsMobile: { value: window.isMobile },
            },
            vertexShader: loadingScreenVertexShader,
            fragmentShader: loadingScreenFragmentShader,
        });
        this.overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
        this.scene.add(this.overlay)
    }

    hideLoadingScreen() {
        const loadingOverlay = document.getElementById('loading-overlay');
        loadingOverlay.style.display = 'none';
        gsap.to(this.overlay.material.uniforms.uAlpha, {
            duration: 1,
            value: 0,
        });

        setTimeout(() => {
            this.overlay.geometry.dispose();
            this.overlay.material.dispose();
            this.scene.remove( this.overlay );
        }, 1000);
    }

    startScene() {
        this.experience.camera.controls.autoRotateSpeed = 20;
        this.initAmbientAudio();
        setTimeout(() => {
            /* Display back button */
            const backButton = document.getElementById('back-button');
            backButton.style.display = 'flex';

            /* Display user instructions */
            if (!window.isMobile) {
                const instructions = document.getElementById('instructions-overlay');
                instructions.style.transform = 'translate(-50%, -50%) scale(1)';
            }

            setTimeout(() => {
                /* Hide user instructions. */
                const instructions = document.getElementById('instructions-overlay');
                instructions.style.transform = 'translate(-50%, -50%) scale(0)';
            }, 5000);
            this.experience.camera.controls.autoRotate = false;
            if (!window.isMobile) {
                goToSceneCoord(this.experience.camera, initialStartCoord, 2);
            } else {
                goToSceneCoord(this.experience.camera, mobileInitialStartCoord, 2);
            }
        }, 2000);

    }
}

import * as THREE from 'three';
import Experience from './Experience';

export default class Audio {
  constructor(camera) {
    const listener = new THREE.AudioListener();
    camera.instance.add(listener);
    this.sound = new THREE.Audio(listener);
    this.sound.setVolume( 0.1 );
    this.experience = new Experience();
  }

  playClick() {
    this.setPlaybackRate(1);
    this.setDuration(0.5);
    this.play(this.experience.resources.items.audioClick);
  }

  play(soundBuffer, loop=false) {
    if (this.sound.isPlaying) {
      this.sound.stop();
    }
    this.sound.setBuffer( soundBuffer );
    this.sound.setLoop( loop );
    this.sound.play();

    // Reset any settings after play
    this.reset();
  }

  setDuration(duration) {
    this.sound.duration = duration;
  }

  setPlaybackRate(playbackRate) {
    this.sound.playbackRate = playbackRate;
  }

  setOffset(offset) {
    this.sound.offset = offset;
  }

  reset() {
    this.sound.duration = undefined;
    this.sound.playbackRate = 1;
    this.sound.offset = 0;
  }
}

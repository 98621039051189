export default [
    /* Room scene */
    {
        name: 'roomModel',
        type: 'gltfModel',
        // path: 'models/Room/room1-unwrap5.glb',
        path: 'models/FishingVillage/fishing_village_house_shop.glb',
    },
    {
        name: 'roomTexture',
        type: 'texture',
        // path: 'models/Room/baked5.jpg',
        path: 'models/FishingVillage/baked_house_shop.jpg',
    },

    {
        name: 'roomModel2',
        type: 'gltfModel',
        // path: 'models/Room/room1-unwrap5.glb',
        path: 'models/FishingVillage/fishing_village_cliff_planks.glb',
    },
    {
        name: 'roomTexture2',
        type: 'texture',
        // path: 'models/Room/baked5.jpg',
        path: 'models/FishingVillage/baked_cliff_planks.jpg',
    },

    {
        name: 'roomModel3',
        type: 'gltfModel',
        // path: 'models/Room/room1-unwrap5.glb',
        path: 'models/FishingVillage/fishing_village_rest.glb',
    },
    {
        name: 'roomTexture3',
        type: 'texture',
        // path: 'models/Room/baked5.jpg',
        path: 'models/FishingVillage/baked_rest.jpg',
    },
    {
        name: 'roomModel4',
        type: 'gltfModel',
        // path: 'models/Room/room1-unwrap5.glb',
        path: 'models/FishingVillage/fishing_village_sushi_roof.glb',
    },
    {
        name: 'roomTexture4',
        type: 'texture',
        // path: 'models/Room/baked5.jpg',
        path: 'models/FishingVillage/baked_sushi_roof.jpg',
    },
    {
        name: 'interactableModels',
        type: 'gltfModel',
        // path: 'models/Room/room1-unwrap5.glb',
        path: 'models/FishingVillage/fishing_village_interactables.glb',
    },

    /* Careers */
    {
        name: 'snappymob',
        type: 'texture',
        path: 'textures/career/snappymob.png',
    },
    {
        name: 'compass',
        type: 'texture',
        path: 'textures/career/compass.png',
    },
    {
        name: 'configura',
        type: 'texture',
        path: 'textures/career/configura.png',
    },

    /* Reviews */
    {
        name: 'reviewStart',
        type: 'texture',
        path: 'textures/reviews/review-start.png',
    },
    {
        name: 'review1',
        type: 'texture',
        path: 'textures/reviews/review1_1.png',
    },
    {
        name: 'review2',
        type: 'texture',
        path: 'textures/reviews/review2_1.png',
    },
    {
        name: 'review3',
        type: 'texture',
        path: 'textures/reviews/review3_1.png',
    },
    {
        name: 'review4',
        type: 'texture',
        path: 'textures/reviews/review4_1.png',
    },
    {
        name: 'review5',
        type: 'texture',
        path: 'textures/reviews/review5_1.png',
    },

    /* Signboard */
    {
        name: 'careerSign',
        type: 'texture',
        path: 'textures/signboard/career-sign.png',
    },
    {
        name: 'reviewSign',
        type: 'texture',
        path: 'textures/signboard/review-sign.png',
    },
    {
        name: 'contactSign',
        type: 'texture',
        path: 'textures/signboard/contact-sign.png',
    },

    /* About me text */
    {
        name: 'aboutMe',
        type: 'texture',
        path: 'textures/about-me.png',
    },

    /* Audio */
    {
        name: 'audioClick',
        type: 'audio',
        path: 'audio/click.mp3',
    },
    {
        name: 'audioWhoosh',
        type: 'audio',
        path: 'audio/whoosh.mp3',
    },
    {
        name: 'audioOcean',
        type: 'audio',
        path: 'audio/ocean_waves.mp3',
    },
    {
        name: 'audioHeavenlyAmbience',
        type: 'audio',
        path: 'audio/heavenly_ambience.mp3',
    },
]
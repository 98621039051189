import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        this.sources = sources

        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0
        this.progressRatio = 0;

        this.setLoaders()
        this.startLoading()
    }

    setLoaders()
    {
        this.loadingManager = new THREE.LoadingManager();

        this.loadingManager.onLoad = function ( ) {
            // Delay 500ms so that the progress animation can complete nicely.
            setTimeout(() => {
                // Remove loading UI
                const loadingUi = document.getElementById('loading');
                loadingUi.style.display = 'none';

                // Display Start UI
                const startUi = document.getElementById('start');
                startUi.style.display = 'flex';

            }, 500);
        };


        this.loadingManager.onProgress =  ( url, itemsLoaded, itemsTotal ) => {
            this.progressRatio = itemsLoaded / itemsTotal;
            this.trigger('progress');
            console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
        };
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader(this.loadingManager)
        const dracoLoader = new DRACOLoader(this.loadingManager);
        dracoLoader.setDecoderPath( 'draco/gltf/' );
        this.loaders.gltfLoader.setDRACOLoader( dracoLoader );
        this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingManager)
        this.loaders.audioLoader = new THREE.AudioLoader(this.loadingManager);
    }

    startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'audio')
            {
                this.loaders.audioLoader.load(
                    source.path,
                    (buffer) =>
                    {
                        this.sourceLoaded(source, buffer)
                    }
                )
            }
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file

        this.loaded++

        if(this.loaded === this.toLoad)
        {
            this.trigger('ready')
        }
    }
}
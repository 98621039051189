import gsap, { Power1, } from 'gsap';
import { reviews } from '../CameraPositions';

export const navigateToLink = (link) => {
  const a = document.createElement("a");
  a.href = link;
  a.target = "_blank";
  a.click();
}

export const goToSceneCoord = (camera, sceneCoord, duration = 1, delay = 0) => {
  camera.setOriginalCameraCoord();
  const tween = gsap.to(camera.instance.position, {
      duration,
      x: sceneCoord.position[0],
      y: sceneCoord.position[1],
      z: sceneCoord.position[2],
      paused: true,
      ease: Power1.easeOut
    });
  tween.play();
  gsap.to(camera.controls.target, {
      duration,
      x: sceneCoord.target[0],
      y: sceneCoord.target[1],
      z: sceneCoord.target[2],
      paused: true,
      ease: Power1.easeOut,
    }).play();
}

import * as THREE from 'three'

import Debug from './Utils/Debug.js'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Audio from './Audio.js';
import Renderer from './Renderer.js'
import World from './World/World.js'
import Resources from './Utils/Resources.js'

import sources from './sources.js'
import Mouse from './Utils/Mouse.js'
import { goToSceneCoord, navigateToLink } from './Utils/Navigation.js'
import { careerCoord, contactsCoord, mobileCareerCoord, mobileReviewsCoord, reviewsCoord } from './CameraPositions.js'
import ActiveWindow from './Utils/ActiveWindow.js'

let instance = null

export default class Experience
{
    constructor(_canvas)
    {
        // Singleton
        if(instance)
        {
            return instance
        }
        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = _canvas

        // Setup
        this.debug = new Debug()
        this.sizes = new Sizes()
        this.time = new Time()
        this.scene = new THREE.Scene()
        this.resources = new Resources(sources)
        this.camera = new Camera()
        this.audio = new Audio(this.camera);
        this.ambientAudio = new Audio(this.camera);
        this.ambientAudio2 = new Audio(this.camera);
        this.renderer = new Renderer()
        this.world = new World()
        this.mouse = new Mouse(this.sizes);
        this.activeWindow = new ActiveWindow();

        /* Raycaster */
        this.raycaster = new THREE.Raycaster();
        this.currentIntersect;
        this.interactables = [];

        /* Flag for preventing use to click on
           interactable planes while in focus on plane mode. */
        this.userInScene = false;

        // Resize event
        this.sizes.on('resize', () =>
        {
            this.resize()
        })

        // Time tick event
        this.time.on('tick', () =>
        {
            this.update()
        })
    }

    resize()
    {
        this.camera.resize()
        this.renderer.resize()
    }

    update()
    {
        /* Handle mouse intersect with Raycaster */
        this.raycaster.setFromCamera(this.mouse._mouse, this.camera.instance);
        const intersects = this.raycaster.intersectObjects(this.interactables);
        if (intersects.length > 0) {
            /* Mouse is hovering over interactable  */
            this.currentIntersect = intersects[0];

            /* Update cursor to pointer style */
            document.body.style.cursor = 'pointer';
        } else {
            if (this.currentIntersect) {
                /* Update cursor to default style */
                if (document.body.style.cursor !== 'auto') {
                    document.body.style.cursor = 'auto';
                }
                this.currentIntersect = undefined;
            }
        }

        this.camera.update()
        this.world.update()
        this.renderer.update()
    }

    destroy()
    {
        this.sizes.off('resize')
        this.time.off('tick')

        // Traverse the whole scene
        this.scene.traverse((child) =>
        {
            // Test if it's a mesh
            if(child instanceof THREE.Mesh)
            {
                child.geometry.dispose()

                // Loop through the material properties
                for(const key in child.material)
                {
                    const value = child.material[key]

                    // Test if there is a dispose function
                    if(value && typeof value.dispose === 'function')
                    {
                        value.dispose()
                    }
                }
            }
        })

        this.camera.controls.dispose()
        this.renderer.instance.dispose()

        if(this.debug.active)
            this.debug.ui.destroy()
    }

    setClickInteraction() {
        this.mouse.on('click', () => {
            if (!this.currentIntersect) {
                return;
            }
            const targetInteractable = this.interactables.find(x => this.currentIntersect.object === x);
            if (!targetInteractable) {
                return;
            }

            this.audio.playClick();
            setTimeout(() => {
                switch (targetInteractable.name) {
                    case 'medium':
                        navigateToLink('https://medium.com/@tanweishen97')
                        break;
                    case 'linkedin':
                        navigateToLink('https://www.linkedin.com/in/wei-shen-tan-21613618a/')
                        break;
                    case 'github':
                        navigateToLink('https://github.com/MrMagicPickle')
                        break;
                    case 'leetcode':
                        navigateToLink('https://leetcode.com/mrmagicpenguin/')
                        break;
                    case 'review_plane':
                        if (this.userInScene) {
                            return;
                        }
                        this.setReviewControls();
                        if (!window.isMobile) {
                            goToSceneCoord(this.camera, reviewsCoord);
                        } else {
                            goToSceneCoord(this.camera, mobileReviewsCoord);
                        }
                        break;
                    case 'review_sign':
                        this.setReviewControls();
                        if (!window.isMobile) {
                            goToSceneCoord(this.camera, reviewsCoord);
                        } else {
                            goToSceneCoord(this.camera, mobileReviewsCoord);
                        }
                        break;
                    case 'career_plane':
                        if (this.userInScene) {
                            return;
                        }
                        this.setCareerControls();
                        if (!window.isMobile) {
                            goToSceneCoord(this.camera, careerCoord);
                        } else {
                            goToSceneCoord(this.camera, mobileCareerCoord);
                        }
                        break;
                    case 'career_sign':
                        this.setCareerControls();
                        if (!window.isMobile) {
                            goToSceneCoord(this.camera, careerCoord);
                        } else {
                            goToSceneCoord(this.camera, mobileCareerCoord);
                        }
                        break;
                    case 'contact_sign':
                        /* Update back icon to text */
                        const backIcon = document.getElementById('back-icon');
                        backIcon.style.display = 'none';

                        const backText = document.getElementById('back-text');
                        backText.style.display = 'block';
                        goToSceneCoord(this.camera, contactsCoord);
                        break;
                    default:
                        console.log('found ', targetInteractable.name);
                        break;
                }
                this.audio.setPlaybackRate(1);
                this.audio.setDuration(1);
                this.audio.setOffset(0.5);
                this.audio.play(this.resources.items.audioWhoosh);
            }, 100);

        });
    }

    startScene() {
        /* User click interaction */
        this.setClickInteraction();

        /* Determines if window is active. */
        this.activeWindow.on('activeWindow', () => {
            if (!this.ambientAudio.sound.isPlaying) {
                this.ambientAudio.play(
                    this.resources.items.audioOcean,
                    true
                );
            }
        });
        this.activeWindow.on('inactiveWindow', () => {
            if (this.ambientAudio.sound.isPlaying) {
                this.ambientAudio.sound.stop();
            }
            if (this.ambientAudio2.sound.isPlaying) {
                this.ambientAudio2.sound.stop();
            }
        });
        this.world.startScene();
    }

    setCareerControls() {
        const topControls = document.querySelector('.center-controls');
        topControls.style.visibility = 'visible';

        const careerText = document.getElementById('career-control-text');
        careerText.style.display = 'block';

        const reviewText = document.getElementById('review-control-text');
        reviewText.style.display = 'none';

        /* Update back icon to text */
        const backIcon = document.getElementById('back-icon');
        backIcon.style.display = 'none';

        const backText = document.getElementById('back-text');
        backText.style.display = 'block';

        const prevButton = document.querySelector('.controls-back');
        prevButton.onclick = () => {
            this.world.prevCareerItem();
        };

        const nextbutton = document.querySelector('.controls-next');
        nextbutton.onclick = () => {
            this.world.nextCareerItem();
        };
        this.userInScene = true;
    }

    setReviewControls() {
        const topControls = document.querySelector('.center-controls');
        topControls.style.visibility = 'visible';

        const careerText = document.getElementById('career-control-text');
        careerText.style.display = 'none';

        const reviewText = document.getElementById('review-control-text');
        reviewText.style.display = 'block';

        /* Update back icon to text */
        const backIcon = document.getElementById('back-icon');
        backIcon.style.display = 'none';

        const backText = document.getElementById('back-text');
        backText.style.display = 'block';

        const prevButton = document.querySelector('.controls-back');
        prevButton.onclick = () => {
            this.world.prevReviewItem();
        };

        const nextbutton = document.querySelector('.controls-next');
        nextbutton.onclick = () => {
            this.world.nextReviewItem();
        };

        // Set review screen to start from index 1;
        this.world.nextReviewItem();

        this.userInScene = true;
    }

    resetTopControls() {
        const topControls = document.querySelector('.center-controls');
        topControls.style.visibility = 'hidden';

        /* Update back text to home icon */
        const backIcon = document.getElementById('back-icon');
        backIcon.style.display = 'block';

        const backText = document.getElementById('back-text');
        backText.style.display = 'none';

        // Set review screen to be at start screen index 0;
        if (this.world.currentReviewIndex !== 0) {
            this.world.currentReviewIndex = 0;
            this.world.reviewPlane.material = this.world.reviewMaterials[this.world.currentReviewIndex];
        }
        this.userInScene = false;
    }
}
import * as THREE from 'three';
import lightParticlesVertexShader from './shaders/vertex.glsl'
import lightParticlesFragmentShader from './shaders/fragment.glsl'

/**
 * lightParticles
 */
// Geometry
const lightParticlesGeometry = new THREE.BufferGeometry()
const lightParticlesCount = 100
const positionArray = new Float32Array(lightParticlesCount * 3)
const scaleArray = new Float32Array(lightParticlesCount)

for(let i = 0; i < lightParticlesCount; i++)
{
    positionArray[i * 3 + 0] = (Math.random() - 0.5) * 20
    positionArray[i * 3 + 1] = Math.random() * 6
    positionArray[i * 3 + 2] = (Math.random() - 0.5) * 20

    scaleArray[i] = Math.random() * 4
}

lightParticlesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray, 3))
lightParticlesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

// Material
const lightParticlesMaterial = new THREE.ShaderMaterial({
    uniforms:
    {
        uTime: { value: 0 },
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) },
        uSize: { value: 100 },
        uColor: {value: new THREE.Color('#51D3AD') },
    },
    vertexShader: lightParticlesVertexShader,
    fragmentShader: lightParticlesFragmentShader,
    transparent: true,
    blending: THREE.AdditiveBlending,
    depthWrite: false
});
const lightParticles = new THREE.Points(lightParticlesGeometry, lightParticlesMaterial);
export { lightParticles };
import EventEmitter from "./EventEmitter";

export default class ActiveWindow extends EventEmitter {
  constructor(sizes) {
    super();

    document.addEventListener("visibilitychange", onchange => {
      if (!document.hidden) {
        this.trigger('activeWindow');
      }
      else {
        this.trigger('inactiveWindow');
      }
    });
  }
}
import * as THREE from 'three'
import Experience from '../../Experience.js'
import EnvironmentMaterial from './EnvironmentMaterial';

export default class Floor
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Colors
        this.colors = {}

        // Dark blue hue
        this.colors.topLeft = '#06072d'
        this.colors.topRight = '#06072d'

        this.colors.bottomLeft = '#51D3AD'
        this.colors.bottomRight = '#51D3AD'

        this.setGeometry()
        this.setTextures()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry()
    {
        // Bruno
        this.geometry = new THREE.PlaneBufferGeometry(2, 2, 10, 10);

    }

    setTextures()
    {
    }

    setMaterial()
    {
        this.material = new EnvironmentMaterial();
        this.updateMaterial = () =>
        {
            const topLeft = new THREE.Color(this.colors.topLeft)
            const topRight = new THREE.Color(this.colors.topRight)
            const bottomRight = new THREE.Color(this.colors.bottomRight)
            const bottomLeft = new THREE.Color(this.colors.bottomLeft)

            const data = new Uint8Array([
                Math.round(bottomLeft.r * 255), Math.round(bottomLeft.g * 255), Math.round(bottomLeft.b * 255), 255,
                Math.round(bottomRight.r * 255), Math.round(bottomRight.g * 255), Math.round(bottomRight.b * 255), 255,
                Math.round(topLeft.r * 255), Math.round(topLeft.g * 255), Math.round(topLeft.b * 255), 255,
                Math.round(topRight.r * 255), Math.round(topRight.g * 255), Math.round(topRight.b * 255), 255,
            ])

            this.backgroundTexture = new THREE.DataTexture(data, 2, 2, THREE.RGBAFormat)
            this.backgroundTexture.magFilter = THREE.LinearFilter
            this.backgroundTexture.needsUpdate = true

            this.material.uniforms.tBackground.value = this.backgroundTexture
        }

        this.updateMaterial()
    }

    setMesh()
    {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.frustumCulled = false
        this.mesh.matrixAutoUpdate = false
        this.mesh.updateMatrix()
        this.scene.add(this.mesh)
    }
}
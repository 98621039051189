import './style.css'

import Experience from './Experience/Experience.js'

// window.isMobile = true;
if ('ontouchstart' in document.documentElement) {
  window.isMobile = true;
} else {
  window.isMobile = false;
}

const experience = new Experience(document.querySelector('canvas.webgl'))
window.back = () => {
  experience.audio.playClick();
  setTimeout(() => {
    experience.camera.returnToHomeCoord();
  }, 100);

};

window.prevItem = () => {
  experience.world.prevCareerItem();
};

window.nextItem = () => {
  experience.world.nextCareerItem();
};

window.prevReview = () => {
  experience.world.prevReviewItem();
};

window.nextReview = () => {
  experience.world.nextReviewItem();
};

window.start = () => {
  experience.audio.playClick();

  // Hide overlay;
  experience.world.hideLoadingScreen();

  // Start rotation of camera earlier
  experience.camera.controls.autoRotate = true;

  // Start intro after click audio is played.
  setTimeout(() => {
    experience.startScene();
  }, 100);
}
